<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header" style="padding:0px">
      <el-form :inline="true" size="small" :model="searchSalePerformanceCommissionDetailData" @submit.native.prevent>
        <!-- <el-form-item v-if="storeEntityList.length>1" label="开单门店">
          <el-select v-model="searchSalePerformanceCommissionDetailData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleSalePerformanceCommissionSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="搜索">
          <el-input v-model="searchSalePerformanceCommissionDetailData.EmployeeName" clearable @keyup.enter.native="handleSalePerformanceCommissionSearch" @clear="handleSalePerformanceCommissionSearch" placeholder="输入员工名称搜索"></el-input>
        </el-form-item>
        <el-form-item label="组织单位">
          <el-select v-model="searchSalePerformanceCommissionDetailData.EntityID" clearable filterable placeholder="请选择" :default-first-option="true" @change="handleSalePerformanceCommissionSearch">
            <el-option v-for="item in storeEntityList" :label="item.EntityName" :value="item.ID" :key="item.ID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职务">
          <el-select v-model="searchSalePerformanceCommissionDetailData.JobID" clearable filterable placeholder="请选择卡职务" :default-first-option="true" @change="handleSalePerformanceCommissionSearch">
            <el-option v-for="item in jobTypeData" :label="item.JobName" :value="item.ID" :key="item.ID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员名称">
          <el-input v-model="searchSalePerformanceCommissionDetailData.Name" clearable @keyup.enter.native="handleSalePerformanceCommissionSearch" @clear="handleSalePerformanceCommissionSearch" placeholder="请输入会员名称、编号、手机号"></el-input>
        </el-form-item>
        <el-form-item label="注册时间筛选">
          <el-date-picker v-model="searchSalePerformanceCommissionDetailData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSalePerformanceCommissionSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="时间筛选">
          <el-date-picker v-model="searchSalePerformanceCommissionDetailData.QueryDateTo" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSalePerformanceCommissionSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="最近消耗时间筛选">
          <el-date-picker v-model="searchSalePerformanceCommissionDetailData.QueryTreatDateTo" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSalePerformanceCommissionSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="销售时间筛选">
          <el-date-picker v-model="searchSalePerformanceCommissionDetailData.QuerySaleDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSalePerformanceCommissionSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="首次减肥日期">
          <el-date-picker v-model="searchSalePerformanceCommissionDetailData.FirstTreatDates" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSalePerformanceCommissionSearch"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSalePerformanceCommissionSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="downloadExcel" v-loading="downloadExcel1">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table size="small" show-summary :data="salePerformanceCommissionDetailList" v-loading="salePerformanceCommissionDetailLoading" :summary-method="getsaleTreatDetailListSummaries">
      <el-table-column prop="Name" label="员工姓名"></el-table-column>
      <el-table-column prop="ID" label="员工编号"></el-table-column>
      <el-table-column prop="JobName" label="职务"></el-table-column>
      <el-table-column prop="EntityName" label="所属组织"></el-table-column>
      <el-table-column prop="CustomerName" label="顾客姓名"></el-table-column>
      <el-table-column prop="Code" label="顾客编号"></el-table-column>
      <el-table-column prop="PhoneNumber" label="手机号"></el-table-column>
      <el-table-column prop="CustomerLevelName" label="会员等级"></el-table-column>
      <el-table-column prop="EarlyWeight" label="初始体重(斤)"></el-table-column>
      <el-table-column prop="LightestWeight" label="最轻体重(斤)"></el-table-column>
      <el-table-column prop="LastWeight" label="最近体重(斤)"></el-table-column>
      <el-table-column prop="WarningWeight" label="减重斤数"></el-table-column>
      <el-table-column prop="WeightPercent" label="百分比">
        <template slot-scope="scope">
          <span>{{ scope.row.WeightPercent }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="CustomerEntityName" label="注册门店"></el-table-column>
      <el-table-column prop="CreatedOn" label="注册时间" width="150"></el-table-column>
      <el-table-column prop="LastConsumeBillDate" label="最近消耗时间" width="150"></el-table-column>
      <el-table-column prop="FirstTreatBillDate" label="首次减肥日期" width="150"></el-table-column>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="salePerformanceCommissionDetailPaginations.total > 0"
        @current-change="handleSalePerformanceCommissionPageChange"
        :current-page.sync="salePerformanceCommissionDetailPaginations.page"
        :page-size="salePerformanceCommissionDetailPaginations.page_size"
        :layout="salePerformanceCommissionDetailPaginations.layout"
        :total="salePerformanceCommissionDetailPaginations.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Employee/salePerformanceCommissionDetailStatement";
import permission from "@/components/js/permission.js";
export default {
  name: "loseWeight",

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.salePerformanceCommissionDetailExport = permission.permission(to.meta.Permission, "Report-Employee-SalePerformanceCommissionDetail-Export");
    });
  },
  data() {
    return {
      loading: false,
      downloadLoading: false,
      storeEntityList: [], //门店列表
      jobTypeData: [], // 职务列表
      searchSalePerformanceCommissionDetailData: {
        EmployeeName: "",
        EntityID: null,
        JobID: null,
        Name: "",
        QueryDate: [],
        QueryDateTo: [],
        QueryTreatDateTo: [],
        QuerySaleDate: [],
        FirstTreatDates: [],
      },
      salePerformanceCommissionDetailList: [],
      salePerformanceCommissionDetailSum: {},
      employeeSlimmingStatementSumOutFrom: {},
      salePerformanceCommissionDetailLoading: false,
      //需要给分页组件传的信息
      salePerformanceCommissionDetailPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      salePerformanceCommissionDetailExport: false,
      downloadExcel1: false,
    };
  },
  methods: {
    // 员工职务
    getJobType: function() {
      var that = this;
      var params = {
        JobTypeName: that.JobTypeName,
      };
      API.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    handleSalePerformanceCommissionSearch() {
      var that = this;
      that.salePerformanceCommissionDetailPaginations.page = 1;
      that.salePerformanceCommissionDetail();
    },

    handleSalePerformanceCommissionPageChange(page) {
      this.salePerformanceCommissionDetailPaginations.page = page;
      this.salePerformanceCommissionDetail();
    },
    // 销售搜索
    salePerformanceCommissionDetail() {
      var that = this;
      if (that.searchSalePerformanceCommissionDetailData.QueryDate != null) {
        var params = {
          PageNum: that.salePerformanceCommissionDetailPaginations.page,
          EmployeeName: that.searchSalePerformanceCommissionDetailData.EmployeeName,
          EntityID: that.searchSalePerformanceCommissionDetailData.EntityID,
          JobID: that.searchSalePerformanceCommissionDetailData.JobID,
          Name: that.searchSalePerformanceCommissionDetailData.Name.trim(),
          StartDate: that.searchSalePerformanceCommissionDetailData.QueryDate ? that.searchSalePerformanceCommissionDetailData.QueryDate[0] : "",
          EndDate: that.searchSalePerformanceCommissionDetailData.QueryDate ? that.searchSalePerformanceCommissionDetailData.QueryDate[1] : "",
          SDate: that.searchSalePerformanceCommissionDetailData.QueryDateTo ? that.searchSalePerformanceCommissionDetailData.QueryDateTo[0] : "",
          EDate: that.searchSalePerformanceCommissionDetailData.QueryDateTo ? that.searchSalePerformanceCommissionDetailData.QueryDateTo[1] : "",
          TreatStartDate: that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo ? that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo[0] : "",
          TreatEndDate: that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo ? that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo[1] : "",
          SaleStartDate: that.searchSalePerformanceCommissionDetailData.QuerySaleDate ? that.searchSalePerformanceCommissionDetailData.QuerySaleDate[0] : "",
          SaleEndDate: that.searchSalePerformanceCommissionDetailData.QuerySaleDate ? that.searchSalePerformanceCommissionDetailData.QuerySaleDate[1] : "",

          FirstTreatStartDate: that.searchSalePerformanceCommissionDetailData.FirstTreatDates ? that.searchSalePerformanceCommissionDetailData.FirstTreatDates[0] : "",
          FirstTreatEndDate: that.searchSalePerformanceCommissionDetailData.FirstTreatDates ? that.searchSalePerformanceCommissionDetailData.FirstTreatDates[1] : "",
        };
        that.salePerformanceCommissionDetailLoading = true;
        API.getEmployeeSalePerformanceCommissionDetail(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.salePerformanceCommissionDetailSum = res.List;
              that.salePerformanceCommissionDetailList = res.Data.employeeSlimmingStatementOutFroms.List;
              that.employeeSlimmingStatementSumOutFrom = res.Data.employeeSlimmingStatementSumOutFrom;
              that.salePerformanceCommissionDetailPaginations.total = res.Data.employeeSlimmingStatementOutFroms.Total;
              that.salePerformanceCommissionDetailPaginations.page_size = res.Data.employeeSlimmingStatementOutFroms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.salePerformanceCommissionDetailLoading = false;
          });
      }
    },

    // 合计
    getsaleTreatDetailListSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "WarningWeight":
            sums[index] = <span class="font_weight_600">{filter_NumFormat(this.employeeSlimmingStatementSumOutFrom.WarningWeight ? this.employeeSlimmingStatementSumOutFrom.WarningWeight : "")}</span>;
            break;
          case "WeightPercent":
            sums[index] = <span class="font_weight_600">{this.employeeSlimmingStatementSumOutFrom.WeightPercent}%</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },

    /** 数据导出 */
    downloadExcel() {
      var that = this;
      let params = {
        PageNum: that.salePerformanceCommissionDetailPaginations.page,
        EmployeeName: that.searchSalePerformanceCommissionDetailData.EmployeeName,
        EntityID: that.searchSalePerformanceCommissionDetailData.EntityID,
        JobID: that.searchSalePerformanceCommissionDetailData.JobID,
        Name: that.searchSalePerformanceCommissionDetailData.Name.trim(),
        StartDate: that.searchSalePerformanceCommissionDetailData.QueryDate ? that.searchSalePerformanceCommissionDetailData.QueryDate[0] : "",
        EndDate: that.searchSalePerformanceCommissionDetailData.QueryDate ? that.searchSalePerformanceCommissionDetailData.QueryDate[1] : "",
        SDate: that.searchSalePerformanceCommissionDetailData.QueryDateTo ? that.searchSalePerformanceCommissionDetailData.QueryDateTo[0] : "",
        EDate: that.searchSalePerformanceCommissionDetailData.QueryDateTo ? that.searchSalePerformanceCommissionDetailData.QueryDateTo[1] : "",
        TreatStartDate: that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo ? that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo[0] : "",
        TreatEndDate: that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo ? that.searchSalePerformanceCommissionDetailData.QueryTreatDateTo[1] : "",
        SaleStartDate: that.searchSalePerformanceCommissionDetailData.QuerySaleDate ? that.searchSalePerformanceCommissionDetailData.QuerySaleDate[0] : "",
        SaleEndDate: that.searchSalePerformanceCommissionDetailData.QuerySaleDate ? that.searchSalePerformanceCommissionDetailData.QuerySaleDate[1] : "",
        FirstTreatStartDate: that.searchSalePerformanceCommissionDetailData.FirstTreatDates ? that.searchSalePerformanceCommissionDetailData.FirstTreatDates[0] : "",
        FirstTreatEndDate: that.searchSalePerformanceCommissionDetailData.FirstTreatDates ? that.searchSalePerformanceCommissionDetailData.FirstTreatDates[1] : "",
      };
      that.downloadExcel1 = true;
      API.getdownloadExcel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "员工瘦身报表.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadExcel1 = false;
        });
    },
  },
  mounted() {
    var that = this;
    // 员工职务
    that.getJobType();
    that.salePerformanceCommissionDetailExport = permission.permission(that.$route.meta.Permission, "Report-Employee-SalePerformanceCommissionDetail-Export");
    that.getstoreEntityList();
    that.handleSalePerformanceCommissionSearch();
  },
};
</script>
